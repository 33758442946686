<template>
  <footer class="text-gray-200 text-xs py-3 uppercase">
    <span>{{ $t("app.versions", 1) }} {{ version }}</span>
    <span class="float-right">
      {{ $t("app.copyright") }} {{ currentYear }} {{ company }}
    </span>
  </footer>
</template>

<script>
import { computed } from "vue";
import { useStore } from "@/store";

export default {
  setup() {
    // Misc
    const store = useStore();

    // Computed
    const version = computed(() => store.getters["app/version"]);
    const company = computed(() => store.getters["api/company"]);
    const currentYear = computed(() => new Date().getFullYear());

    return {
      version,
      company,
      currentYear
    };
  }
};
</script>
