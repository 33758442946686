<template>
  <div class="dropdown w-8 h-8" :class="{ 'intro-x': hasAnimation }">
    <div
      class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
      role="button"
      aria-expanded="false"
    >
      <VImage
        :src="avatarURL"
        class="flex items-center justify-center"
        :color="color"
        :name="name"
        :width="8"
        :height="8"
      />
    </div>

    <div id="account-menu" class="dropdown-menu w-56">
      <div
        class="dropdown-menu__content box bg-theme-26 dark:bg-dark-6 text-white"
      >
        <div class="p-4 border-b border-theme-27 dark:border-dark-3">
          <div class="font-medium">
            {{ name }}
          </div>

          <div class="text-xs text-theme-28 mt-0.5 dark:text-gray-600">
            @{{ username }}
          </div>
        </div>

        <div class="p-2">
          <a
            class="cursor-pointer flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
            @click="onClickRedirect('profile')"
          >
            <UserIcon class="w-4 h-4 mr-2" /> {{ $t("app.profile") }}
          </a>

          <a
            class="cursor-pointer flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
            @click="onClickRedirect('profile-memberships')"
          >
            <EditIcon class="w-4 h-4 mr-2" /> {{ $t("app.memberships") }}
          </a>

          <a
            class="cursor-pointer flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
            @click="onClickRedirect('profile-ui-settings')"
          >
            <LockIcon class="w-4 h-4 mr-2" /> {{ $t("app.ui_settings") }}
          </a>
        </div>

        <div class="p-2 border-t border-theme-27 dark:border-dark-3">
          <a
            class="cursor-pointer flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
            @click="onClickLogout"
          >
            <ToggleRightIcon class="w-4 h-4 mr-2" /> {{ $t("app.logout") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
// Composables
import useAnimation from "@/composables/useAnimation";
import useDisplay from "@/composables/useDisplay";
import useColor from "@/composables/useColor";
// Components
import VImage from "@/components/VImage";

export default {
  components: {
    VImage
  },
  setup() {
    // MISC
    const store = useStore();
    const router = useRouter();

    // Composables
    const { hasAnimation } = useAnimation();
    const { getAvatarURL } = useDisplay();
    const { getRandomColors } = useColor();

    // COMPUTED
    const firstname = computed(() => store.getters["user/firstname"]);
    const lastname = computed(() => store.getters["user/lastname"]);
    const color = computed(() => getRandomColors()[0]);
    // eslint-disable-next-line
    const avatarURL = computed(() => getAvatarURL(store.getters["user/avatar"]));
    const username = computed(() => store.getters["user/username"]);
    const name = computed(() => `${firstname.value} ${lastname.value}`);

    // METHODS
    const onClickLogout = () => {
      hideDropdown();
      store.dispatch("user/logout");
      router.push({ name: "login" });
    };

    const onClickRedirect = name => {
      hideDropdown();
      router.push({ name });
    };

    const hideDropdown = () => {
      cash("account-menu").dropdown("hide");
    };

    return {
      color,
      name,
      username,
      avatarURL,
      onClickRedirect,
      onClickLogout,
      // useAnimation
      hasAnimation
    };
  }
};
</script>
