import Velocity from "velocity-animate";

// Setup side menu
const findActiveMenu = (subMenu, route) => {
  let match = false;
  subMenu.forEach(item => {
    if (
      (item.pageName === route.name || hasMatchedRoute(item, route)) &&
      !item.ignore
    ) {
      match = true;
    } else if (!match && item.subMenu) {
      match = findActiveMenu(item.subMenu, route);
    }
  });
  return match;
};

const hasMatchedRoute = (item, route) => {
  return route.matched.some(record => {
    const hasName = record.name === item.pageName;
    const hasParent =
      record.meta.parentName && record.meta.parentName === item.pageName;

    return hasName || hasParent;
  });
};

const nestedMenu = (menu, route) => {
  menu.forEach((item, key) => {
    if (typeof item !== "string") {
      let menuItem = menu[key];
      menuItem.active =
        (item.pageName === route.name ||
          hasMatchedRoute(item, route) ||
          (item.subMenu && findActiveMenu(item.subMenu, route))) &&
        !item.ignore;

      if (item.subMenu) {
        if (!route.matched.some(record => record.name == "home")) {
          menuItem.activeDropdown =
            route.matched.some(record => record.name == item.pageName) ||
            findActiveMenu(item.subMenu, route);
        }
        menuItem = {
          ...item,
          ...nestedMenu(item.subMenu, route)
        };
      }
    }
  });

  return menu;
};

const linkTo = (menu, router, event, menus) => {
  if (menus) {
    menus.forEach(item => {
      item.activeDropdown = false;
    });
  }

  if (menu.subMenu) {
    menu.activeDropdown = !menu.activeDropdown;
    if (
      !menu.pageName &&
      menus &&
      menus.map(item => item.title).includes(menu.title)
    ) {
      router.push({ name: menu.subMenu[0].pageName });
    }
  } else {
    event.preventDefault();
    router.push({ name: menu.pageName });
  }
};

const enter = (el, done) => {
  Velocity(el, "slideDown", { duration: 300 }, { complete: done });
};

const leave = (el, done) => {
  Velocity(el, "slideUp", { duration: 300 }, { complete: done });
};

const getIcon = (item, icon) => {
  return "assets/icon/" + item.title + "/" + icon + ".svg";
};

export { nestedMenu, linkTo, enter, leave, getIcon };
