<template>
  <div class="dropdown mr-auto sm:mr-6" :class="{ 'intro-x': hasAnimation }">
    <div
      class="notification dropdown-toggle cursor-pointer"
      role="button"
      aria-expanded="false"
    >
      <i class="w-5 h-5 fal fa-lg fa-question" />
    </div>

    <div class="w-52 pt-2 dropdown-menu">
      <div class="p-5 dropdown-menu__content box dark:bg-dark-6">
        <div
          class="cursor-pointer relative flex items-baseline overflow-hidden mb-4"
          @click="onClickToggleHints"
        >
          <div class="flex justify-center items-center w-5 h-5 mr-5">
            <i
              class="fal fa-lg"
              :class="
                showHints ? 'fa-lightbulb-on text-theme-1' : 'fa-lightbulb'
              "
            />
          </div>

          <div class="font-medium truncate whitespace-nowrap">
            {{ showHints ? $t("app.hide_hints") : $t("app.show_hints") }}
          </div>
        </div>

        <div
          class="cursor-pointer relative flex items-baseline overflow-hidden mb-4"
          @click="onClickToggleIntro"
        >
          <div class="flex justify-center items-center w-5 h-5 mr-5">
            <i
              class="fa-play fa-lg"
              :class="showIntro ? 'fas text-theme-1' : 'fal'"
            />
          </div>

          <div class="font-medium truncate whitespace-nowrap">
            {{ showIntro ? $t("app.hide_intro") : $t("app.show_intro") }}
          </div>
        </div>

        <div
          class="cursor-pointer relative flex items-baseline overflow-hidden"
          @click="onClickRedirectToManual"
        >
          <div class="flex justify-center items-center w-5 h-5 mr-5">
            <i class="fal fa-book fa-lg" />
          </div>

          <div class="font-medium truncate whitespace-nowrap">
            {{ $t("app.help_manual") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "@/store";
// Composables
import useAnimation from "@/composables/useAnimation";
import useDisplay from "@/composables/useDisplay";

export default {
  setup() {
    // Misc
    const store = useStore();

    // Composables
    const { hasAnimation } = useAnimation();
    const { formatDate } = useDisplay();

    // Computed
    const showHints = computed(() => store.getters["app/showHints"]);
    const showIntro = computed(() => store.getters["app/showIntro"]);

    // Methods
    const onClickToggleHints = () => {
      store.dispatch("app/toggleShowHints");
    };

    // Methods
    const onClickToggleIntro = () => {
      store.dispatch("app/toggleShowIntro");
    };

    const onClickRedirectToManual = () => {
      const support_site = store.getters["api/support_site"];

      if (support_site) {
        window.open(support_site, "_blank");
      }
    };

    return {
      showHints,
      showIntro,
      onClickToggleHints,
      onClickToggleIntro,
      onClickRedirectToManual,
      // useAnimation
      hasAnimation,
      // useDisplay
      formatDate
    };
  }
};
</script>
