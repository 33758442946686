<template>
  <div class="mb-2">
    <div
      v-if="display"
      class="alert alert-danger rounded-none show flex items-center"
      role="alert"
    >
      <AlertCircleIcon class="w-6 h-6 mr-2" />
      <div class="grid">
        <div v-for="(message, index) in displayedMessages" :key="index">
          {{ message }}
        </div>
      </div>
    </div>

    <div
      v-if="personification_name"
      class="alert alert-warning rounded-none show flex items-center justify-between"
      role="alert"
    >
      <div>
        <i class="fal fa-user-crown fa-lg mr-2" />
        <!-- eslint-disable-next-line -->
        <span v-html="$t('app.personification_msg', { name: personification_name })" />
      </div>

      <VButton
        :label="$t('app.stop_impersonating')"
        class="btn-dark btn-sm"
        @click="onClickStopImpersonating"
      />
    </div>
  </div>
</template>

<script>
import { computed, inject } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
// Components
import VButton from "@/components/VButton";

export default {
  components: {
    VButton
  },
  setup() {
    // Misc
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const moment = inject("moment");

    // Computed
    const messages = computed(() => store.getters["messages/messages"]);
    const userLang = computed(() => store.getters["user/language"]);
    const dateFormat = computed(() => store.getters["app/dateFormat"]);
    const defaultLang = computed(() => store.getters["app/defaultLang"]);

    const personification_name = computed(() => {
      return store.getters["user/personification_name"];
    });

    const displayedMessages = computed(() => {
      return Object.keys(messages.value).map(key => {
        return getMessage(key, messages.value[key]);
      });
    });

    const display = computed(() => {
      return displayedMessages.value && displayedMessages.value.length > 0;
    });

    // Methods
    const getMessage = (key, message) => {
      switch (key) {
        case "license_expiry":
          return t(`backend.${message.message}`, {
            date: moment(message.value1).format(dateFormat.value)
          });
        case "maintenance":
          return message[userLang.value] || message[defaultLang.value];
        default:
          return "";
      }
    };

    const onClickStopImpersonating = () => {
      store.dispatch("user/clearPersonification");

      router.push({ name: "home" });
    };

    return {
      display,
      displayedMessages,
      personification_name,
      onClickStopImpersonating
    };
  }
};
</script>
