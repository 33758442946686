<template>
  <div class="top-bar">
    <TheBreadcrumbs />

    <MainSearch />

    <VHelp />

    <VNotifications />

    <AccountMenu />
  </div>
</template>

<script>
import { useStore } from "@/store";
// Composables
import useAnimation from "@/composables/useAnimation";
// Components
import TheBreadcrumbs from "@/components/TheBreadcrumbs";
import MainSearch from "@/components/MainSearch";
import VNotifications from "@/components/VNotifications";
import VHelp from "@/components/VHelp";
import AccountMenu from "@/components/AccountMenu";

export default {
  components: {
    VHelp,
    TheBreadcrumbs,
    MainSearch,
    VNotifications,
    AccountMenu
  },
  setup() {
    // Misc
    const store = useStore();

    // Composables
    const { hasAnimation } = useAnimation();

    // Methods
    const onClickHelp = () => {
      const support_site = store.getters["api/support_site"];

      if (support_site) {
        window.open(support_site, "_blank");
      }
    };

    return {
      onClickHelp,
      // useAnimation
      hasAnimation
    };
  }
};
</script>
