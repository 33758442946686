<template>
  <div class="relative mr-3 sm:mr-6" :class="{ 'intro-x': hasAnimation }">
    <div class="search hidden sm:block">
      <input
        v-model="term"
        type="text"
        class="search__input form-control border-transparent placeholder-theme-13"
        placeholder="Search..."
        @input="onInputSearch"
      />

      <SearchIcon
        v-if="!searchDropdown"
        class="search__icon dark:text-gray-300"
        @click="showSearchDropdown"
      />

      <XIcon
        v-else
        class="search__icon dark:text-gray-300"
        @click="hideSearchDropdown"
      />
    </div>

    <a class="notification sm:hidden" href="">
      <SearchIcon class="notification__icon dark:text-gray-300" />
    </a>

    <div class="search-result" :class="{ show: searchDropdown }">
      <div v-if="isLoading" class="flex justify-center search-result__content">
        <LoadingIcon icon="three-dots" class="w-8 h-8" />
      </div>

      <div v-else-if="!hasValues" class="search-result__content">
        <VAlert :text="$t('app.no_results_found')" />
      </div>

      <div v-else class="search-result__content">
        <div
          v-for="(value, key, index) in displayedResults"
          :key="key"
          :class="[
            { 'mt-2': index !== 0 },
            { 'mb-4': displayedTags.length > 0 }
          ]"
        >
          <div class="search-result__content__title mb-4">
            {{ $t(`app.${key.toLowerCase()}`) }}
          </div>

          <div
            v-for="(subValue, subKey, subIndex) in value"
            :key="`${key}-${subKey}`"
            :class="{ 'mt-2': subIndex !== 0 }"
            class="cursor-pointer"
            @click="onClickRedirectToSearch(subValue.type)"
          >
            <div class="flex justify-between items-center">
              <div class="flex items-center">
                <div
                  class="w-8 h-8 bg-theme-2 text-theme-1 flex items-center justify-center rounded-full"
                >
                  <VAction :icon="subValue.icon" class="w-4 h-4" />
                </div>

                <div class="ml-3">
                  {{ subValue.text }}
                </div>
              </div>

              <div>
                {{ subValue.count }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="displayedTags.length > 0">
          <div class="my-4 border-b border-gray-300 border-dashed" />

          <div class="flex justify-between items-center mb-4">
            <div class="search-result__content__title">
              {{ $t("app.tags", 2) }}
            </div>

            <div
              class="text-theme-13 cursor-pointer self-start"
              @click="onClickRedirectToTags"
            >
              {{ $t("app.view_all").toUpperCase() }}
            </div>
          </div>

          <div
            v-for="(tag, index) in displayedTags"
            :key="index"
            :class="{ 'mt-2': index !== 0 }"
          >
            <div
              class="flex cursor-pointer justify-between items-center"
              @click="onClickRedirectToTag(tag)"
            >
              <div class="flex items-center">
                <div
                  class="w-8 h-8 bg-theme-2 text-theme-1 flex items-center justify-center rounded-full"
                >
                  <VAction icon="tag" class="w-4 h-4" />
                </div>

                <div class="ml-3">
                  {{ tag?.name }}
                </div>
              </div>

              <div>
                {{ getTagCount(tag) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, ref } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
// Composables
import useRequest from "@/composables/useRequest";
import useAnimation from "@/composables/useAnimation";
// Components
import VAlert from "@/components/VAlert";
import VAction from "@/components/tables/VAction";

export default {
  components: {
    VAlert,
    VAction
  },
  setup() {
    // Misc
    const { t } = useI18n();
    const router = useRouter();
    const _ = inject("lodash");

    // Composables
    const { hasAnimation } = useAnimation();
    const { request } = useRequest();

    // Data
    const searchDropdown = ref(false);
    const isLoading = ref(false);
    const results = ref({});
    const tags = ref([]);
    const term = ref("");

    // Computed
    const displayedResults = computed(() => {
      const results_counts = results.value?.results_counts ?? {};

      return Object.keys(results_counts)?.reduce((acc, cv) => {
        const [section, ...subsection] = cv.split("_");
        const textKey = subsection.map(x => x.toLowerCase()).join("_");

        const value = {
          [subsection]: {
            count: results_counts[cv],
            type: cv,
            icon: subsection[0].toLowerCase(),
            text: t(`app.${textKey}`, 2)
          }
        };

        if (value[subsection].count === 0) {
          return acc;
        }

        if (acc[section]) {
          acc[section] = { ...acc[section], ...value };
        } else {
          acc = { ...acc, [section]: value };
        }

        return acc;
      }, {});
    });
    const displayedTags = computed(() => tags.value.slice(0, 5));
    const hasValues = computed(() => {
      const hasResults = Object.keys(displayedResults.value).length > 0;
      const hasTags = tags.value?.length > 0;

      return hasResults || hasTags;
    });

    // Methods
    const showSearchDropdown = () => {
      searchDropdown.value = true;
    };

    const hideSearchDropdown = () => {
      searchDropdown.value = false;
    };

    const onInputSearch = _.debounce(async input => {
      isLoading.value = true;

      const value = input?.target?.value ?? "";

      if (value.length >= 2) {
        showSearchDropdown();
      }

      if (value.length <= 1) {
        results.value = [];
        tags.value = [];
        isLoading.value = false;
        return;
      }

      await Promise.all([search(value), searchTags(value)]);
      showSearchDropdown();

      isLoading.value = false;
    }, 500);

    const encodeFilters = f => encodeURIComponent(JSON.stringify(f));

    const search = async term => {
      const response = await request({
        endpoint: "platform.search",
        queryParams: { term },
        showToaster: false
      });

      results.value = response?.payload?.data ?? {};
    };

    const searchTags = async v => {
      const filters = encodeFilters([
        {
          f: ["name"],
          c: "LIKE",
          v: [v]
        }
      ]);

      const response = await request({
        endpoint: "platform.tags",
        queryParams: {
          ro_f: filters
        }
      });

      tags.value = response?.payload?.data ?? [];
    };

    const onClickRedirectToTag = tag => {
      hideSearchDropdown();
      redirectToTag(tag?.id);
    };

    const onClickRedirectToSearch = type => {
      hideSearchDropdown();
      redirectToSearch(type);
    };

    const redirectToTag = id => {
      router.push({
        name: "tag",
        params: { id }
      });
    };

    const redirectToSearch = type => {
      router.push({
        name: "search",
        params: { type, term: term.value ?? "" }
      });
    };

    const redirectToTags = () => {
      router.push({ name: "tags" });
    };

    const onClickRedirectToTags = () => {
      hideSearchDropdown();
      redirectToTags();
    };

    const getTagCount = tag => {
      return Object.keys(tag).reduce((acc, cv) => {
        if (cv.includes("count")) {
          return acc + tag[cv];
        }

        return acc;
      }, 0);
    };

    return {
      isLoading,
      hasValues,
      term,
      tags,
      displayedTags,
      displayedResults,
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      onInputSearch,
      onClickRedirectToTag,
      onClickRedirectToSearch,
      onClickRedirectToTags,
      getTagCount,
      // useAnimation
      hasAnimation
    };
  }
};
</script>
