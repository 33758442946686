<template>
  <div>
    <SystemWideMessages class="-m-5 mb-5" />
    <MobileMenu />
    <div class="flex">
      <!-- BEGIN: Side Menu -->
      <nav class="side-nav">
        <!-- BEGIN: Logo -->
        <router-link
          :to="{ name: 'home' }"
          tag="a"
          class="flex items-center pb-2"
          :class="{ 'intro-x': hasAnimation }"
        >
          <img alt="Chexpertise" src="@/assets/images/chexpertise.svg" />
        </router-link>
        <!-- END: Logo -->
        <div class="side-nav__divider mb-6 mt-2"></div>
        <ul>
          <!-- eslint-disable-next-line -->
          <template v-for="(menu, menuKey) in displayedHomeMenu" :key="menu + menuKey">
            <li class="relative">
              <SideMenuTooltip
                tag="a"
                :content="$t(`app.${menu.title}`)"
                :href="
                  menu.subMenu
                    ? 'javascript:;'
                    : router.resolve({ name: menu.pageName }).path
                "
                class="side-menu"
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown
                }"
                @click="linkTo(menu, router, $event)"
              >
                <div class="side-menu__icon">
                  <component :is="menu.icon" />
                </div>
                <div class="side-menu__title">
                  {{ $t(`app.${menu.title}`) }}
                  <div
                    v-if="menu.subMenu"
                    class="side-menu__sub-icon"
                    :class="{
                      'transform rotate-180': menu.activeDropdown
                    }"
                  ></div>
                </div>
              </SideMenuTooltip>
              <VDropdown
                v-if="menu.title === 'home'"
                class="absolute top-1 left-3/4 z-50"
                dropdown-menu-class="w-44"
                button-dropdown-menu-class="justify-between w-full bg-transparent"
                :chevron-dropdown-menu-class="
                  displayedMenu[0].active
                    ? 'text-black w-5 h-5'
                    : 'text-white w-5 h-5'
                "
                hide-dropdown-on-click
                selected=""
                :options="displayedMenuInDropdown"
                @click="
                  (selected = $event),
                    linkTo(selected, router, $event, displayedMenu)
                "
              >
                <template #option="{ option }">
                  <div
                    class="cursor-pointer p-2 flex justify-between items-center"
                  >
                    <div
                      v-if="option.icon"
                      class="side-menu__icon h-6 w-6 mt-1 text-center"
                    >
                      <component :is="option.icon" />
                    </div>
                    <div class="truncate w-2/3">
                      {{ $t(`app.${option.title}`) }}
                    </div>
                  </div>
                </template>
              </VDropdown>
            </li>
          </template>
          <div class="side-nav__divider mt-6 mb-2"></div>

          <!-- eslint-disable-next-line -->
          <template v-for="(menu, menuKey) in displayedMenu" :key="menu + menuKey">
            <li>
              <a
                href="javascript:;"
                class="side-menu__module side-menu side-menu--active side-menu--open"
                :style="{ display: 'none' }"
              ></a>
              <transition @enter="enter" @leave="leave">
                <ul v-if="menu.subMenu && menu.activeDropdown">
                  <li
                    v-for="(firstSubMenu, firstSubMenuKey) in menu.subMenu"
                    :key="firstSubMenuKey"
                  >
                    <SideMenuTooltip
                      v-if="hasMenuRoles(firstSubMenu)"
                      tag="a"
                      :content="$t(`app.${firstSubMenu.title}`)"
                      :href="
                        firstSubMenu.subMenu
                          ? 'javascript:;'
                          : router.resolve({ name: firstSubMenu.pageName }).path
                      "
                      class="side-menu"
                      :class="{
                        'side-menu--active': firstSubMenu.active,
                        'side-menu--open': firstSubMenu.activeDropdown
                      }"
                      @click="linkTo(firstSubMenu, router, $event)"
                    >
                      <div class="side-menu__icon h-6 w-6 mt-1 text-center">
                        <i :class="firstSubMenu.icon" />
                      </div>

                      <div class="side-menu__title">
                        {{ $t(`app.${firstSubMenu.title}`, 2) }}
                        <div
                          v-if="firstSubMenu.subMenu"
                          class="side-menu__sub-icon"
                          :class="{
                            'transform rotate-180': firstSubMenu.activeDropdown
                          }"
                        >
                          <ChevronDownIcon />
                        </div>
                      </div>
                    </SideMenuTooltip>
                    <!-- BEGIN: Second Child -->
                    <transition @enter="enter" @leave="leave">
                      <ul
                        v-if="
                          firstSubMenu.subMenu && firstSubMenu.activeDropdown
                        "
                      >
                        <li
                          v-for="(subMenu, subMenuKey) in firstSubMenu.subMenu"
                          :key="subMenuKey"
                        >
                          <SideMenuTooltip
                            tag="a"
                            :content="$t(`app.${subMenu.title}`, 2)"
                            :href="
                              subMenu.subMenu
                                ? 'javascript:;'
                                : router.resolve({ name: subMenu.pageName })
                                    .path
                            "
                            class="side-menu"
                            :class="{ 'side-menu--active': subMenu.active }"
                            @click="linkTo(subMenu, router, $event)"
                          >
                            <div
                              class="side-menu__icon h-6 w-6 mt-1 text-center"
                            >
                              <i :class="subMenu.icon" />
                            </div>
                            <div class="side-menu__title">
                              {{ $t(`app.${subMenu.title}`, 2) }}
                              <div
                                v-if="subMenu.subMenu"
                                class="side-menu__sub-icon"
                                :class="{
                                  'transform rotate-180': subMenu.activeDropdown
                                }"
                              >
                                <ChevronDownIcon />
                              </div>
                            </div>
                          </SideMenuTooltip>
                          <!-- BEGIN: Third Child -->
                          <transition @enter="enter" @leave="leave">
                            <ul
                              v-if="subMenu.subMenu && subMenu.activeDropdown"
                            >
                              <li
                                v-for="(lastSubMenu,
                                lastSubMenuKey) in subMenu.subMenu"
                                :key="lastSubMenuKey"
                              >
                                <SideMenuTooltip
                                  tag="a"
                                  :content="$t(`app.${lastSubMenu.title}`)"
                                  :href="
                                    lastSubMenu.subMenu
                                      ? 'javascript:;'
                                      : router.resolve({
                                          name: lastSubMenu.pageName
                                        }).path
                                  "
                                  class="side-menu"
                                  :class="{
                                    'side-menu--active': lastSubMenu.active
                                  }"
                                  @click="linkTo(lastSubMenu, router, $event)"
                                >
                                  <div
                                    class="side-menu__icon h-6 w-6 mt-1 text-center"
                                  >
                                    <i :class="lastSubMenu.icon" />
                                  </div>
                                  <div class="side-menu__title">
                                    {{ $t(`app.${lastSubMenu.title}`) }}
                                  </div>
                                </SideMenuTooltip>
                              </li>
                            </ul>
                          </transition>
                          <!-- END: Third Child -->
                        </li>
                      </ul>
                    </transition>
                    <!-- END: Second Child -->
                  </li>
                </ul>
              </transition>
            </li>
          </template>
          <!-- END: First Child -->
        </ul>

        <div class="learning-hub pt-36">
          <VButton
            icon="fa-code-branch"
            class="btn-elevated-rounded w-full mr-1 mb-2 bg-white text-center"
            @click="onClickLearningHub"
          >
            <div class="uppercase">
              {{ $t("app.learning_hub") }}
            </div>
          </VButton>
        </div>
      </nav>
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->
      <div class="flex-1">
        <div class="content h-full">
          <TopBar />
          <router-view />
        </div>
        <TheFooter />
      </div>
      <!-- END: Content -->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { helper as $h } from "@/utils/helper";
// Composables
import useAnimation from "@/composables/useAnimation";
// Constants
import systemRoles from "@/constants/systemRoles";
// Components
import TopBar from "@/components/TopBar";
import SystemWideMessages from "@/components/SystemWideMessages";
import MobileMenu from "@/components/mobile-menu/Main";
import SideMenuTooltip from "@/components/side-menu-tooltip/Main";
import TheFooter from "@/components/TheFooter";
import VButton from "@/components/VButton";
import VDropdown from "@/components/VDropdown";
import { linkTo, nestedMenu, enter, leave, getIcon } from "./index";

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    SideMenuTooltip,
    TheFooter,
    SystemWideMessages,
    VButton,
    VDropdown
  },
  setup() {
    // Misc
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    // Composables
    const { hasAnimation } = useAnimation();

    // Data
    const formattedMenu = ref([]);
    const selected = ref({});

    // Computed
    const sideMenu = computed(() =>
      nestedMenu(store.state.sideMenu.menu, route)
    );
    const userRoles = computed(() => store.getters["user/roles"]);
    // eslint-disable-next-line
    const clientModules = computed(
      () => store.getters["app/available_modules"]
    );
    const displayedMenu = computed(() => {
      return formattedMenu.value.filter(menu => {
        return hasMenuRoles(menu) && isModuleAvailable(menu);
      });
    });

    const displayedMenuInDropdown = computed(() => {
      return displayedMenu.value.filter(
        elem => elem.subMenu || elem.title === "wiki_docs"
      );
    });

    const displayedHomeMenu = computed(() => {
      return displayedMenu.value.filter(
        elem => !elem.subMenu && elem.title !== "wiki_docs"
      );
    });

    // Watchers
    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value);
      }
    );

    // Lifecycle Hooks
    onMounted(() => {
      cash("body")
        .removeClass("error-page")
        .removeClass("login")
        .addClass("main p-5");
      formattedMenu.value = $h.toRaw(sideMenu.value);
      expandMenu();
    });

    // Methods
    const expandMenu = () => {
      formattedMenu.value = formattedMenu.value.map(menu => ({
        ...menu,
        activeDropdown: isActiveDropdown(menu.title) || menu.activeDropdown
      }));
      updateSelected(
        formattedMenu.value.filter(menu => menu.activeDropdown)[0]
      );
    };

    const isActiveDropdown = menuTitle => {
      const isAdmin = hasRole([systemRoles.SYSTEM_ADMIN, systemRoles.ADMIN]);
      const isTeacher = hasRole([systemRoles.INSTRUCTOR]);
      const isLearner = hasRole([systemRoles.LEARNER]);

      switch (menuTitle) {
        case "administration":
          return isAdmin;
        case "teaching":
          return !isAdmin && isTeacher;
        case "learning":
          return !isAdmin && !isTeacher && isLearner;
        default:
          return false;
      }
    };

    const isModuleAvailable = menu => {
      const name = menu.moduleName;

      return name ? clientModules.value.includes(name) : true;
    };

    const hasMenuRoles = menu => {
      return menu.authorizedRoles?.some(role => hasRole([role])) ?? true;
    };

    const hasRole = roles => {
      return userRoles.value?.some(role => roles.includes(role.code)) ?? false;
    };

    const onClickLearningHub = () => {
      const learning_hub = store.getters["api/learning_hub"];

      if (learning_hub) {
        window.open(learning_hub, "_blank");
      }
    };

    const updateSelected = value => {
      selected.value = value;
    };

    return {
      displayedMenu,
      displayedMenuInDropdown,
      displayedHomeMenu,
      router,
      linkTo,
      enter,
      leave,
      getIcon,
      onClickLearningHub,
      hasMenuRoles,
      // useAnimation
      hasAnimation,
      selected
    };
  }
});
</script>
