<template>
  <div class="dropdown mr-auto sm:mr-6" :class="{ 'intro-x': hasAnimation }">
    <div
      class="dropdown-toggle notification cursor-pointer"
      :class="{ 'notification--bullet': displayedNotifications.length !== 0 }"
      role="button"
      aria-expanded="false"
    >
      <BellIcon class="notification__icon dark:text-gray-300" />
    </div>

    <div class="notification-content pt-2 dropdown-menu">
      <div
        class="notification-content__box dropdown-menu__content box dark:bg-dark-6"
      >
        <div class="text-base notification-content__title">
          {{ $t("app.notifications", 2) }}
        </div>

        <div v-if="isLoading" class="flex justify-center">
          <LoadingIcon icon="three-dots" class="w-8 h-8" />
        </div>

        <div v-else-if="displayedNotifications.length === 0">
          <VAlert :text="$t('app.no_unread_notifications')" />
        </div>

        <div
          v-for="(notification, index) in displayedNotifications"
          :key="index"
          class="relative flex items-center"
          :class="{ 'mt-5': index !== 0 }"
        >
          <div class="w-12 h-12 flex-none image-fit mr-1">
            <img
              :alt="notification?.data?.sent_by?.name"
              class="rounded-full"
              :src="getAvatar(notification)"
            />
          </div>

          <div class="w-full ml-2 overflow-hidden">
            <div class="flex items-center">
              <div class="font-medium truncate mr-5">
                {{ notification?.data?.sent_by?.name }}
              </div>

              <div class="text-xs text-gray-500 ml-auto whitespace-nowrap">
                {{ formatDate({ date: notification?.updated_at }) }}
              </div>
            </div>

            <div class="w-full truncate text-gray-600 mt-0.5">
              {{ getNotificationTitle(notification) }}
            </div>
          </div>
        </div>

        <div class="pb-4 border-b border-gray-300 border-dashed" />

        <VButton
          :label="$t('app.view_all')"
          class="btn-outline-secondary w-full mt-4"
          @click="onClickRedirectToNotifications"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
// Composables
import useDisplay from "@/composables/useDisplay";
import useAnimation from "@/composables/useAnimation";
import useRequest from "@/composables/useRequest";
// Components
import VButton from "@/components/VButton";
import VAlert from "@/components/VAlert";

export default {
  components: {
    VButton,
    VAlert
  },
  setup() {
    // Misc
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();

    // Constants
    const MAX_UNREAD_NOTIFICATION = 5;

    // Computed
    const userId = computed(() => store.getters["user/id"]);
    const unreadNotifications = computed(() => {
      return store.getters["notifications/unread"] ?? [];
    });
    const displayedNotifications = computed(() => {
      return unreadNotifications.value.slice(0, MAX_UNREAD_NOTIFICATION);
    });
    const client = computed(() => store.getters["app/client"]);

    // Composables
    const { request } = useRequest();
    const { getAvatarURL, formatDate } = useDisplay();
    const { hasAnimation } = useAnimation();

    // Data
    const isLoading = ref(false);

    // Methods
    const getNotificationTitle = notification => {
      const [type] = notification?.type?.split("\\").slice(-1);

      return t(`notifications.${type}`);
    };

    const onClickRedirectToNotifications = () => {
      router.push({
        name: "home-notifications"
      });
    };

    const getData = async () => {
      isLoading.value = true;

      const response = await request({
        endpoint: "my.notifications.unread-all",
        queryParams: {
          ro_p: MAX_UNREAD_NOTIFICATION
        }
      });

      const notifications = response?.payload?.data ?? [];
      store.dispatch("notifications/setUnreadNotifications", notifications);

      isLoading.value = false;
    };

    const addUnreadNotification = notification => {
      store.dispatch("notifications/addUnreadNotification", notification);
    };

    const listenToNotifications = () => {
      const channel = `${client.value}.notifications.user.${userId.value}`;

      window.Echo.private(channel).notification(notification => {
        addUnreadNotification(notification);
      });
    };

    const getAvatar = notification => {
      const { name, avatar } = notification?.data?.sent_by ?? {};

      if (!name || !avatar) {
        return;
      }

      if (name === "Chexpertise") {
        return avatar;
      }

      return getAvatarURL(avatar);
    };

    // Lifecycle Hooks
    onMounted(async () => {
      listenToNotifications();
      await getData();
    });

    return {
      displayedNotifications,
      isLoading,
      onClickRedirectToNotifications,
      getNotificationTitle,
      getAvatar,
      // useDisplay
      getAvatarURL,
      formatDate,
      // useAnimation
      hasAnimation
    };
  }
};
</script>
