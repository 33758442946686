<template>
  <div
    class="breadcrumb mr-auto hidden sm:flex"
    :class="{ '-intro-x': hasAnimation }"
  >
    <div v-for="(route, index) in routes" :key="index">
      <router-link
        :to="{ name: route.meta.parentName || route.name }"
        class="capitalize"
        :class="{
          'breadcrumb--active':
            $route.path === route.path || $route.name === route.name
        }"
      >
        {{ route.meta.title }}
      </router-link>
      <ChevronRightIcon
        v-if="routes.length - 1 !== index"
        class="breadcrumb__icon"
      />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
// Composables
import useAnimation from "@/composables/useAnimation";

export default {
  setup() {
    // Misc
    const route = useRoute();

    // Composables
    const { hasAnimation } = useAnimation();

    // Computed
    const routes = computed(() => {
      return route.matched.reduce((acc, cv) => {
        return cv?.meta?.title ? [...acc, cv] : acc;
      }, []);
    });

    return {
      routes,
      // useAnimation
      hasAnimation
    };
  }
};
</script>
